import * as yup from "yup";

import {
  OfferRedemptionMechanic,
  PromotionType
} from "../../clients/services/sams/promotion/types";
import { Status, SunProduct } from "../../clients/services/sams/types";

function containsWhitespace(str: string) {
  return /\s/.test(str);
}
const today = new Date();
today.setHours(0, 0, 0, 0);

const validationSchema = yup.object({
  code: yup
    .string()
    .required("Xcode is required")
    .min(3, "XCode length must not be less than 3 characters")
    .max(30, "XCode length must not be more than 30 characters")
    .test(
      "This field cannot contain white space",
      "This field cannot contain white space",
      (value) => !containsWhitespace(value as string)
    ),
  name: yup
    .string()
    .required("Name of the promotion is required")
    .min(3, "Name of the promotion must not be less than 3 characters")
    .max(45, "Name of the promotion must not be more than 45 characters"),
  promotionType: yup
    .mixed()
    .oneOf(Object.values(PromotionType))
    .required("The type of the promotion is required"),
  codeCollectStartDate: yup
    .date()
    .when("isUpdateOperation", {
      is: false,
      then: yup.date().when("promotionType", {
        is: (value: PromotionType) =>
          value !== PromotionType.NONCODE && value !== PromotionType.DONATION,
        then: (schema) =>
          schema.min(today, "Code collect start date can't be in the past")
      })
    })
    .when("promotionType", {
      is: (value: PromotionType) =>
        value !== PromotionType.NONCODE && value !== PromotionType.DONATION,
      then: (schema) => schema.required("Code collect start date is required")
    }),
  codeCollectEndDate: yup.date().when("promotionType", {
    is: (value: PromotionType) =>
      value !== PromotionType.NONCODE && value !== PromotionType.DONATION,
    then: (schema) =>
      schema
        .required("Code collect end date is required")
        .min(
          yup.ref("codeCollectStartDate"),
          "Code collect end date can't be before start date"
        )
  }),
  redemptionStartDateTime: yup
    .date()
    .nullable()
    .when("promotionType", {
      is: (value: PromotionType) =>
        [
          PromotionType.REGULAR,
          PromotionType.NONCODE,
          PromotionType.DONATION
        ].includes(value),
      then: (schema) => schema.required("Redemption start date is required")
    })
    .when("promotionType", {
      is: (value: PromotionType) => value === PromotionType.REGULAR,
      then: (schema) =>
        schema.min(
          yup.ref("codeCollectStartDate"),
          "Redemption start date can't be before the code collect start date"
        )
    }),
  redemptionEndDateTime: yup
    .date()
    .when("promotionType", {
      is: (value: PromotionType) =>
        value !== PromotionType.COMPETITION && value !== PromotionType.WALLET,
      then: (schema) =>
        schema.min(
          yup.ref("redemptionStartDateTime"),
          "Redemption end date can't be before start date"
        )
    })
    .when(["promotionType", "alwaysOn"], {
      is: (promotionType: PromotionType, alwaysOn: boolean) =>
        promotionType !== PromotionType.NONCODE && !alwaysOn,
      then: (schema) => schema.required("Redemption end date is required")
    }),
  bookingCodeEndDate: yup
    .date()
    .nullable()
    .when(["promotionType", "redemptionMechanic"], {
      is: (
        promotionType: PromotionType,
        redemptionMechanic: OfferRedemptionMechanic
      ) =>
        promotionType === PromotionType.REGULAR &&
        redemptionMechanic === OfferRedemptionMechanic.BOOKING_CODE,
      then: (schema) =>
        schema
          .required("Unique booking code end date is required")
          .min(
            yup.ref("redemptionEndDateTime"),
            "Unique booking code end date can't be before redemption end date"
          )
    })
    .when("redemptionMechanic", {
      is: (redemptionMechanic: OfferRedemptionMechanic) =>
        redemptionMechanic === OfferRedemptionMechanic.BOOKING_CODE,
      then: (schema) =>
        schema.min(
          yup.ref("redemptionEndDateTime"),
          "Unique booking code end date can't be before redemption end date"
        )
    }),
  redemptionThreshold: yup.number().when("promotionType", {
    is: (value: PromotionType) =>
      value !== PromotionType.NONCODE && value !== PromotionType.DONATION,
    then: (schema) =>
      schema
        .required("Redemption threshold is required")
        .min(0, "Redemption threshold must not be less than 0")
  }),
  allowMultipleRedemption: yup.bool(),
  maximumRedemptions: yup.number(),
  image: yup.string().required("Featured image for the promotion is required"),
  headline: yup.string().required("Headline is required"),
  shortHeadline: yup.string().required("Short headline is required"),
  sell: yup
    .string()
    .required("Sell text is required")
    .min(3, "Sell text must not be less than 3 characters")
    .max(255, "Sell text must not be more than 255 characters"),
  slug: yup
    .string()
    .required("Slug is required")
    .min(3, "Slug must not be less than 3 characters")
    .max(255, "Slug must not be more than 255 characters")
    .test(
      "This field cannot contain white space",
      "This field cannot contain white space",
      (value) => !containsWhitespace(value as string)
    ),
  optInButtonText: yup
    .string()
    .nullable()
    .when(["redemptionMechanic", "promotionType", "sunProduct"], {
      is: (
        redemptionMechanic: OfferRedemptionMechanic,
        promotionType: PromotionType,
        sunProduct: SunProduct
      ) =>
        promotionType !== PromotionType.NONCODE ||
        ((sunProduct === SunProduct.SUN_CLUB ||
          sunProduct === SunProduct.MEMBERS_ENCLOSURE) &&
          redemptionMechanic === OfferRedemptionMechanic.BOOKING_CODE),
      then: (schema) =>
        schema
          .required("Opt-In button text is required")
          .min(3, "Opt-In button text must not be less than 3 characters")
          .max(255, "Opt-In button text must not be more than 255 characters")
    }),
  redemptionButtonText: yup.string().when("promotionType", {
    is: (value: PromotionType) =>
      [
        PromotionType.REGULAR,
        PromotionType.NONCODE,
        PromotionType.WALLET
      ].includes(value),
    then: (schema) => schema.required("Redemption button text is required")
  }),
  redemptionLinkUrl: yup
    .string()
    .url("Redemption Link Url must be a valid URL.")
    .when("promotionType", {
      is: (value: PromotionType) =>
        [
          PromotionType.REGULAR,
          PromotionType.NONCODE,
          PromotionType.WALLET,
          PromotionType.DONATION
        ].includes(value),
      then: (schema) => schema.required("Redemption link url is required")
    }),
  resendButtonText: yup
    .string()
    .nullable()
    .when(["redemptionMechanic", "promotionType", "sunProduct"], {
      is: (
        redemptionMechanic: OfferRedemptionMechanic,
        promotionType: PromotionType,
        sunProduct: SunProduct
      ) =>
        redemptionMechanic === OfferRedemptionMechanic.INTERNAL &&
        promotionType !== PromotionType.NONCODE &&
        sunProduct === SunProduct.SUN_SAVERS,
      then: (schema) => schema.required("Resend button text is required")
    }),
  offerContent: yup.string().required("Offer content is required"),
  actions: yup.array().of(yup.string()),
  dnaActions: yup.array().of(yup.string()),
  termsAndConditions: yup.string(),
  internalReview: yup.bool(),
  previewCpns: yup.string().when("internalReview", {
    is: true,
    then: (schema) => schema.required("Preview CPN list is required")
  }),
  publicationStatus: yup.mixed().oneOf(Object.values(Status)),
  showMarketingPermissions: yup
    .bool()
    .nullable()
    .when(["promotionType", "sunProduct"], {
      is: (promotionType: PromotionType, sunProduct: SunProduct) =>
        [PromotionType.REGULAR, PromotionType.COMPETITION].includes(
          promotionType
        ) || sunProduct === SunProduct.SUN_CLUB,
      otherwise: yup
        .bool()
        .oneOf(
          [false],
          "Offer type should one of Regular and Competitions or Sun Product should be Sun Club!"
        )
    }),
  partnerName: yup
    .string()
    .nullable()
    .when("showMarketingPermissions", {
      is: true,
      then: (schema) => schema.required("Partner Name list is required")
    }),
  privacyPolicyUrl: yup
    .string()
    .url()
    .nullable()
    .when("showMarketingPermissions", {
      is: true,
      then: (schema) =>
        schema.required("Privacy Policy Url Name list is required")
    })
});

export default validationSchema;
